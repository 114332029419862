import React, { useRef, useState} from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from "moment";
import Calendar from "assets/images/calendar-icon.svg";
import "bootstrap-daterangepicker/daterangepicker.css";
import {useTranslation} from "react-i18next";
import i18n from "i18next";

const ThisMonth = [
  moment().startOf('month').format("DD.MM.YYYY"), moment().format("DD.MM.YYYY")
];

const lastMonth = [
  moment().subtract(1, 'month').startOf('month').format("DD.MM.YYYY"),
  moment().subtract(1, 'month').endOf('month').format("DD.MM.YYYY"),
];

const ThisMonthForBack = [
  moment().startOf('month').format("YYYY-MM-DD"),
  moment().format("YYYY-MM-DD")
];

const lastMonthForBack = [
  moment().subtract(1, 'month').startOf('month').format("YYYY-MM-DD"),
  moment().subtract(1, 'month').endOf('month').format("YYYY-MM-DD"),
];

const PeriodCalendar = ({
                          onChangePeriod,
                          period,
                          additionBtn = false,
                          forAllPeriod = null,
                        }) => {

  const [value, setValue] = useState(period);
  const calendarRef = useRef();
  const {t} = useTranslation();
  const lang = i18n.language;

  const changeDate = (start, end) => {
    calendarRef.current.setStartDate(start);
    calendarRef.current.setEndDate(end);
  };
  return <div className={"calendar-wrapper mr-0 w-100"} style={{minWidth:"220px"}}>
    <DateRangePicker
      ref={calendarRef}
      initialSettings={{
        startDate: period[0], endDate: period[1],
        locale: {
          locale: moment.locale(lang),
          cancelLabel: t("cancel"),
          applyLabel: t("apply"),
          format: "DD.MM.YYYY"
        },
      }}

      onApply={(e, picker) => {
        setValue([
          moment(picker.startDate._d).format("DD.MM.YYYY"),
          moment(picker.endDate._d).format("DD.MM.YYYY")

        ]);
        changeDate(moment(picker.startDate._d), moment(picker.endDate._d))
        onChangePeriod([
          moment(picker.startDate._d).format("YYYY-MM-DD"),
          moment(picker.endDate._d).format("YYYY-MM-DD"),
          // new Date(picker.startDate._d).toLocaleDateString().split(".").reverse().join("-"),
          // new Date(picker.endDate._d).toLocaleDateString().split(".").reverse().join("-")
        ])
      }}
    >
      <div className={"position-relative"}>
        <input type="text"
               className="form-control pl-3 pr-4"
               style={{paddingLeft: "32px"}}
               value={`${period[0] + " - " + period[1]}`}
               onChange={(e) => setValue(e)}
        />
        <img className={"calendar-icon"} src={Calendar} alt={"calendar"} height={16}/>
      </div>
    </DateRangePicker>
    {additionBtn && <div className={"text-right"}>
      <button
        className={`btn-transparent font-size-12  ${value[0] === lastMonth[0] && value[1] === lastMonth[1] ?
          "text-primary" :
          "text-grey5"}`}
        onClick={() => {
          changeDate(lastMonth[0], lastMonth[1])
          setValue(lastMonth);
          onChangePeriod(lastMonthForBack)
        }}>{t("lastMonth")}
      </button>
      <button
        className={`btn-transparent font-size-12  ${value[0] === ThisMonth[0] && value[1] === ThisMonth[1] ?
          "text-primary" :
          "text-grey5"}`}
        onClick={() => {
          changeDate(ThisMonth[0], ThisMonth[1])
          setValue(ThisMonth);
          onChangePeriod(ThisMonthForBack)
        }}>{t("currentMonth")}
      </button>
      {forAllPeriod ? <button
        className={`btn-transparent font-size-12  ${value[0] === forAllPeriod[0] && value[1] === forAllPeriod[1] ?
          "text-primary" :
          "text-grey5"}`}
        onClick={() => {
          changeDate(forAllPeriod[0], forAllPeriod[1])
          setValue(forAllPeriod);
          let forBack = [
            moment(forAllPeriod[0], "DD.MM.YYYY").format("YYYY-MM-DD"),
            moment(forAllPeriod[1], "DD.MM.YYYY").format("YYYY-MM-DD"),
          ]
          onChangePeriod(forBack)
        }}>{t("forAllPeriod")}
      </button> : null}
    </div>}
  </div>
}

export default PeriodCalendar;