import React, {memo, useMemo, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

import checkRights from "common/checkRights";
import {getTableData} from "reduxStore/reducers/tableData";
import {getAuth} from "reduxStore/reducers/auth";
import {getToggleSidebar} from "reduxStore/reducers/toggleSidebar";

import BonusIcon from "assets/images/bonus_icon.svg";
import Client from "assets/images/client.svg";
import CCIcon from "assets/images/clients.svg";
import Accounts from "assets/images/accounts.svg";
import fb from "assets/images/services/fb-sidebar.svg";
import resetPayline from "assets/images/resetPayline.svg";
import Payline from "assets/images/payline.svg";
import Commission from "assets/images/commission.svg";
import Google from "assets/images/services/google-sidebar.svg";
import manualManagement from "assets/images/manualManagement.svg";
import TT from "assets/images/services/icon-tt-sidebar.svg";
import Arrow from "assets/images/arrow-select.svg";
import FinRequests from "assets/images/finRequests.svg";
import Providers from "assets/images/Providers.svg";
import Reporting from "assets/images/Reporting.svg";
import Sentinel from "assets/images/sentinel.svg";

import BigLogo from "assets/images/white-logo.svg";
import SmLogo from "assets/images/logo-sm.svg";
import ClientsList from "assets/images/clients-list.svg";
import ClientsActive from "assets/images/clients-active.svg";
import {Roles} from "../common/RoleFormatter";
import {useTranslation} from "react-i18next";
import {getCookie} from "../utils/api";
import {authConstants} from "../constants/authConstants";
import AttentiveIcon from "assets/images/attentionIcon.svg?svgr";
import {alarmLimit} from "../routes/MccGoogleAds/MCCGoogleAds";
import {appUrls} from "../constants/appUrls";


const SidebarElemHasSub = memo(({img, label, setShowSub, showSub, marker}) => {
  const {isOpen} = useSelector(getToggleSidebar);


  return (
    <li className={`border-top border-light cursor-pointer`}
        onClick={() => setShowSub(!showSub)}
    >
      <div className={`sidebar-link text-nowrap d-flex w-100 align-items-center`}>
        <div className="sidebar-icon py-3">
          <img src={img} alt=" " width={18} height={18}/>
          {!isOpen && <div className={"d-flex pt-3 justify-content-center align-items-center w-100"}>
            <img className={`rotateArrow ${showSub ? "" : "active"}`} src={Arrow} alt={"show submenu"}/>
          </div>}
        </div>
        <div className={`text-nowrap sidebar-text d-flex align-items-center`}>
          <div style={{lineHeight: "20px"}}>{label}</div>
          {marker ? marker() : null}
        </div>
        <div className={`px-3 ${isOpen ? " " : "d-none"}`}>
          <img className={`rotateArrow ${showSub ? "" : "active"}`} src={Arrow} alt={"show submenu"}/>
        </div>
      </div>
    </li>
  )
});

const SidebarElemLink = memo(({url, img, marker, label, className, classForSub = ""}) => {
  let {pathname} = useLocation();

  return (
    <li key={url} className={`${className} border-top border-light`}>
      <Link
        className={`sidebar-link text-nowrap d-flex w-100 py-3 align-items-center ${
          pathname === url ? "active" : ""
        }`}
        to={url}
      >
        <div className="sidebar-icon">
          <img src={img} alt=" " width={18} height={18}/>
        </div>
        <div
          className={`text-nowrap sidebar-text d-flex align-items-center ${classForSub}`}
        >
          <div style={{lineHeight: "20px"}}>{label}</div>
          {marker ? marker() : null}
        </div>
      </Link>
    </li>
  )
});

const SubLinkElement = memo(({subLinks, showSub}) => {
  const {right} = useSelector(getAuth);

  return (<ul className={"navbar-nav flex-wrap"}>{subLinks.map(({url, label, img, marker = null, rights}) => {
    return checkRights(rights, right) ? <SidebarElemLink key={url}
                                                         url={url}
                                                         img={img}
                                                         marker={marker}
                                                         label={label}
                                                         classForSub={"pl-3"}
                                                         className={`${!showSub ? "hidden " : ""} children-link`}/> : null
  })}</ul>)
})


const LinkElement = memo(({url, label, marker, img, subLinks, show = false}) => {
  const [showSub, setShowSub] = useState(show);
  return (<>{
      subLinks ? <>
        <SidebarElemHasSub img={img} label={label} setShowSub={setShowSub} showSub={showSub} marker={marker}/>
        <SubLinkElement subLinks={subLinks} showSub={showSub}/>
      </> : <SidebarElemLink key={url} url={url} img={img} marker={marker} label={label} className={''}/>
    }</>
  )
});

const Marker = memo(({newNotes = "0", oldNotes = "0"}) => {
  return (<>
    {(+newNotes !== 0 || +oldNotes !== 0) &&
      <div className={`d-block sidebar-marker-wrap ml-2 `}>
        {+oldNotes !== 0 && <span className={`sidebar-marker ${+newNotes === 0 ? "pr-1" : ""}`}>{oldNotes}</span>}
        {+newNotes !== 0 && <span className={"sidebar-marker active"}>{newNotes} {"new"} </span>}
      </div>
    }</>)
});

const ShowAttention = memo(() => {
  return <AttentiveIcon className={"d-block mx-2"} width={24} height={24} fill={"#FFED47"}/>
})

const Sidebar = () => {
  const {sidebarInfo} = useSelector(getTableData);
  const {isOpen} = useSelector(getToggleSidebar);
  const {right: userRight} = useSelector(getAuth);
  const {t} = useTranslation();
  const showSentinel = (getCookie(authConstants.hasPrivilege) === "true")


  const LINKS = useMemo(() => (
    [
      {
        url: appUrls.home,
        label: t("clients"),
        rights: [Roles.ROLE_ADMIN, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_HEAD, Roles.ROLE_SUPPORT, Roles.ROLE_TEAM_LEAD],
        img: CCIcon,
        needMarker: false,
        subLinks: [
          {
            url: appUrls.home,
            label: t("clientsList"),
            rights: [Roles.ROLE_ADMIN, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_HEAD, Roles.ROLE_SUPPORT, Roles.ROLE_TEAM_LEAD],
            img: ClientsList,
            needMarker: false,
          },
          {
            url: appUrls.accountsActive,
            label: t("clientActive"),
            rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_TEAM_LEAD, Roles.ROLE_SUPPORT, Roles.ROLE_ACCOUNTING],
            img: ClientsActive,
            needMarker: false,
          },
        ]
      },


      {
        url: appUrls.partnerProgramClients,
        label: t("partnerProgram"),
        rights: [Roles.ROLE_ADMIN, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_HEAD, Roles.ROLE_SUPPORT, Roles.ROLE_TEAM_LEAD],
        img: CCIcon,
        needMarker: false,
        subLinks: [
          {
            url: appUrls.partnerProgramClients,
            label: t("clientAndTerms"),
            rights: [Roles.ROLE_ADMIN, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_HEAD, Roles.ROLE_SUPPORT, Roles.ROLE_TEAM_LEAD],
            img: ClientsList,
            needMarker: false,
          },
          {
            url: appUrls.partnerProgramRequests,
            label: t("requestsPP"),
            rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_TEAM_LEAD, Roles.ROLE_SUPPORT, Roles.ROLE_ACCOUNTING],
            img: ClientsActive,
            marker: () => <Marker newNotes={sidebarInfo?.partnerProgramRequests}/>,
            // marker: () => <Marker newNotes={10}/>,
            needMarker: true,
          },
        ]
      },

      {
        url: appUrls.adAccount,
        label: t("adAccounts"),
        rights: [Roles.ROLE_ADMIN, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_HEAD, Roles.ROLE_SUPPORT, Roles.ROLE_TEAM_LEAD],
        img: Accounts,
        needMarker: true,
      },
      {
        url: appUrls.providers,
        label: t("providers"),
        rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD],
        img: Providers,
        needMarker: true,
      },
      {
        url: appUrls.finApplications,
        label: t("finApplications"),
        rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_TEAM_LEAD, Roles.ROLE_ACCOUNTING, Roles.ROLE_FINANCIER],
        img: FinRequests,
        needMarker: false,
        marker: () => <Marker newNotes={sidebarInfo?.applicationsNew} oldNotes={sidebarInfo?.applicationsAll}/>,
      },

      {
        url: appUrls.requestFB,
        label: `Facebook ${t("requests")}`,
        marker: () => <Marker newNotes={sidebarInfo?.facebookRegNew} oldNotes={sidebarInfo?.facebookRegAll}/>,
        key: "facebook",
        rights: [Roles.ROLE_ADMIN, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_HEAD, Roles.ROLE_SUPPORT, Roles.ROLE_TEAM_LEAD, Roles.TECHNICAL_SPECIALIST],
        img: fb,
        needMarker: false,
      },

      {
        url: appUrls.requestTikTok,
        label: `TikTok ${t("requests")}`,
        marker: () => <Marker newNotes={sidebarInfo?.tikTokRegNew} oldNotes={sidebarInfo?.tikTokRegAll}/>,
        key: "TikTok",
        rights: [Roles.ROLE_ADMIN, Roles.ROLE_ACCOUNTING, Roles.ROLE_HEAD, Roles.ROLE_TEAM_LEAD, Roles.ROLE_SUPPORT, Roles.TECHNICAL_SPECIALIST],
        img: TT,
        needMarker: false,
      },

      {
        url: appUrls.paymentInfo,
        label: t("resetPLShort"),
        marker: () => <Marker newNotes={sidebarInfo?.requiresEditingPayLine}/>,
        key: "payment_info",
        rights: [Roles.ROLE_ADMIN, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_HEAD, Roles.ROLE_TEAM_LEAD],
        img: resetPayline,
        needMarker: false,
      },
      {
        url: appUrls.payers,
        label: t("checkPLShort"),
        marker: () => <Marker newNotes={sidebarInfo?.verifyPayLineNew} oldNotes={sidebarInfo?.verifyPayLine}/>,
        key: "payers",
        rights: [Roles.ROLE_ADMIN, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_HEAD, Roles.ROLE_SUPPORT, Roles.ROLE_TEAM_LEAD],
        img: Payline,
        needMarker: false
      },
      {
        url: appUrls.googleMcc,
        label: t("GoogleMcc"),
        rights: [Roles.ROLE_ADMIN, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_HEAD, Roles.ROLE_SUPPORT, Roles.ROLE_TEAM_LEAD],
        img: Google,
        marker: () => sidebarInfo?.freeMcc?.filter(item => item <= alarmLimit)?.length ? <ShowAttention/> : null,
        // marker: () => <Marker newNotes={sidebarInfo?.freeMcc} oldNotes={sidebarInfo?.dontHavMcc}/>,
        needMarker: true,
      },
      {
        url: appUrls.manualPayment,
        label: t("manualOperation"),
        rights: [Roles.ROLE_ADMIN, Roles.ROLE_FINANCIER, Roles.ROLE_HEAD, Roles.ROLE_TEAM_LEAD, Roles.ROLE_SUPPORT],
        img: manualManagement,
        needMarker: false,
        subLinks: [
          {
            url: appUrls.manualPayment,
            label: t("manualPayment"),
            rights: [Roles.ROLE_ADMIN, Roles.ROLE_FINANCIER, Roles.ROLE_HEAD, Roles.ROLE_TEAM_LEAD, Roles.ROLE_SUPPORT],
            img: manualManagement,
            needMarker: false,
          },
          {
            url: appUrls.manualDeduct,
            label: t("manualDeducts"),
            rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD],
            img: manualManagement,
            needMarker: false,
          },
          {
            url: appUrls.penalty,
            label: t("fine"),
            rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_TEAM_LEAD],
            img: manualManagement,
            needMarker: false,
          },
        ],
      },
      {
        url: appUrls.commissionIndividual,
        label: t("commissions"),
        rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_TEAM_LEAD],
        img: Commission,
        needMarker: false,
      },
      {
        url: appUrls.invoicePayments,
        label: t("invoicePaymentTitle"),
        rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_ACCOUNTING, Roles.ROLE_SALE_MANAGER],
        img: BonusIcon,
        // marker: () => <Marker newNotes={sidebarInfo?.paymentProblems}/>,
        marker: () => <Marker oldNotes={sidebarInfo?.paymentProblems}/>,
        needMarker: false,
      },
      {
        url: appUrls.users,
        label: t("users"),
        rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_TEAM_LEAD],
        img: Client,
        needMarker: false,

        subLinks: [
          {
            url: appUrls.users,
            label: t("users"),
            rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_TEAM_LEAD],
            img: Client,
            needMarker: false,
          },
          {
            url: appUrls.teams,
            label: t("accessToProject"),
            rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_TEAM_LEAD],
            img: Accounts,
            needMarker: false,
          },
        ]
      },
      {
        url: appUrls.reports,
        label: t("reports"),
        rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_TEAM_LEAD],
        img: Reporting,
        needMarker: false,
        subLinks: [
          {
            url: appUrls.reports,
            label: t("reports"),
            rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_TEAM_LEAD],
            img: Reporting,
            needMarker: false,
          },
          {
            url: appUrls.reportsRate,
            label: t("reportsRate"),
            rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_TEAM_LEAD],
            img: Reporting,
            needMarker: false,
          },
        ]
      },
      {
        url: appUrls.googleTax,
        label: t("googleTax"),
        rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_TEAM_LEAD],
        img: Reporting,
        needMarker: false,

      },
      {
        url: appUrls.screening,
        label: t("screening"),
        rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_SALE_MANAGER, Roles.ROLE_ACCOUNTING, Roles.TECHNICAL_SPECIALIST],
        img: Reporting,
        needMarker: false,

      },
      {
        url: appUrls.advPartner,
        label: t("advPartner"),
        rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD],
        img: Reporting,
        needMarker: false,

      },
    ]
  ), [sidebarInfo, t]);


  return (
    <div className={`sidebar shadow ${isOpen ? "open" : ""}`}>
      <Link
        to={"/"}
        className="d-flex justify-content-center p-2 mt-2 mb-3"
        style={{height: "40px"}}
      >
        <img
          className="h-100"
          src={isOpen ? BigLogo : SmLogo}
          alt={"logo"}
          style={!isOpen ? {maxWidth: "40px"} : null}
        />
      </Link>

      <div className="" style={{height: "calc(100vh - 100px)", overflow: "auto"}}>

        <ul className="navbar-nav flex-wrap">
          {LINKS.map(
            ({url, label, rights, img, marker = null, subLinks = null}) => {
              return checkRights(rights, userRight) && <LinkElement
                key={url}
                url={url}
                show={url === "/"}
                label={label}
                img={img}
                marker={marker}
                subLinks={subLinks || null}
              />
            }
          )}

          {showSentinel ? <li className={`border-top border-light`}>
            <a
              className={`sidebar-link text-nowrap d-flex w-100 py-3 align-items-center`}
              href={"https://admin.adverton.pro/sentinel/"}
              target={"_blank"}
            >
              <div className="sidebar-icon">
                <img src={Sentinel} alt=" " width={18} height={18}/>
              </div>
              <div
                className={`text-nowrap sidebar-text d-flex align-items-center`}
              >
                <div style={{lineHeight: "20px"}}>{"Sentinel"}</div>

              </div>
            </a>
          </li> : null}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
