import React, {memo} from 'react';
import FbIcon from "assets/images/services/fb.svg";
import FbIconSmall from "assets/images/services/fb-small.svg";
import GoogleServ from "assets/images/services/google-serv.svg";
import GoogleSmall from "assets/images/services/google-small.svg";
import YandexServ from "assets/images/services/yandex-serv.svg";
import YandexSmall from "assets/images/services/yandex-small.svg";
import TitTokSmall from "assets/images/services/tiktok-small.svg";
import TitTok from "assets/images/services/tiktok.svg";
import YandexMarket from "assets/images/services/Yandex_Market.svg";
import YandexWebmaster from "assets/images/services/Webmaster.svg";
import VK from "assets/images/services/Vkontakte.svg";
import VKAds from "assets/images/services/VKAds.svg";
import MyTarget from "assets/images/services/MyTarget.svg";
import tw from "assets/images/services/tw.svg";
import linkedin from "assets/images/services/linkedin.svg";
import i18next from "i18next";

export const ServiceIdType = {
  Google: 1,
  Facebook: 3,
  TikTok: 4,
  Yandex: 5,
}

export const ServiceIdForUTM = {
  Google: 1,
  TikTok: 8,
  twitter: 9,
  linkedin:10,
  Facebook: 4,
  VK: 5,
  VKAds: 6,
  MyTarget: 7,
  Yandex: 2,
  YandexMarket: 3,
}

export const serviceName = Object.freeze({
  Google: "Google",
  Facebook: "Facebook",
  TikTok: "TikTok",
  Yandex: "Yandex"
});
export const ServiceIconForUtm = ({serviceType, width=100, height=24}) => {
  switch (serviceType) {
    case ServiceIdForUTM.Google:
      return <img src={GoogleServ} alt={"google service"} width={width} height={height}/>
    case ServiceIdForUTM.TikTok:
      return <img src={TitTok} alt={"TikTok service"} width={width} height={height}/>
    case ServiceIdForUTM.Yandex:
      return <img src={YandexServ} alt={"Yandex service"} width={width} height={height}/>
    case ServiceIdForUTM.Facebook:
      return <img src={FbIcon} alt={"Facebook service"} width={width} height={height}/>
    case ServiceIdForUTM.YandexMarket:
      return <img src={YandexMarket} alt={"YandexMarket service"} width={width} height={height}/>
    case ServiceIdForUTM.YandexWebmaster:
      return <img src={YandexWebmaster} alt={"YandexWebmaster service"} width={width} height={height}/>
    case ServiceIdForUTM.VK:
      return <img src={VK} alt={"VK service"} width={width} height={height}/>
    case ServiceIdForUTM.VKAds:
      return <img src={VKAds} alt={"VKAds service"} width={width} height={height}/>
    case ServiceIdForUTM.MyTarget:
      return <img src={MyTarget} alt={"MyTarget service"} width={width} height={height}/>
    case ServiceIdForUTM.twitter:
      return <img src={tw} alt={"service twitter"} width={width} height={height}/>
    case ServiceIdForUTM.linkedin:
      return <img src={linkedin} alt={"service linkedin"} width={width} height={height}/>
  }
}

export const FullServiceList = [1, 3, 4, 5];

export const ServiceTypesImg = (serviceType, t = i18next.t, width = "auto", height = 16, isBig = true) => {
  // console.log("serviceType ServiceTypesImg", serviceType)
  switch (serviceType) {
    case 1:
    case "G":
      return <img src={isBig ? GoogleServ : GoogleSmall} alt={"google service"} width={width} height={height}/>
    case 5:
    case "YA":
      return <img src={isBig ? YandexServ : YandexSmall} alt={"yandex direct service"} width={width} height={height}/>
    case "F":
    case 3:
      return <img src={isBig ? FbIcon : FbIconSmall} alt={"facebook service"} width={width} height={height}/>
    case "TT":
    case 4:
      return <img src={isBig ? TitTok : TitTokSmall} alt={"TitTok service"} width={width} height={height}/>
    case null:
      return <div className={"font-weight-medium"}>{t("total")}</div>
    default:
      return <>{i18next.t("noService")}</>
  }
}

export const ServiceFormatter = memo(({servId}) => {
  return ServiceTypesImg(+servId);
});

export const ServiceText = (serviceType) => {
  // console.log("serviceType ServiceText", serviceType)
  switch (serviceType) {
    case 1:
    case "G":
      return serviceName.Google
    case 5:
    case "YA":
      return serviceName.Yandex
    case 3:
    case "F":
      return serviceName.Facebook
    case 4:
    case "TT":
      return serviceName.TikTok
    default:
      return <>{i18next.t("noService")}</>
  }
}

export const ServiceOptionsText = [
  {label: i18next.t("all"), value: i18next.t("all")},
  {label: serviceName.Google, value: serviceName.Google},
  {label: serviceName.Facebook, value: serviceName.Facebook},
  {label: serviceName.TikTok, value: serviceName.TikTok},
  {label: serviceName.Yandex, value: serviceName.Yandex}
];

export const ServiceOptionsNum = [
  // {label: i18next.t("all"), value: i18next.t("all")},
  {label: serviceName.Google, value: ServiceIdType.Google},
  {label: serviceName.Facebook, value: ServiceIdType.Facebook},
  {label: serviceName.TikTok, value: ServiceIdType.TikTok},
  {label: serviceName.Yandex, value: ServiceIdType.Yandex}
]

export const ServiceOptions = (serviceTypes, width = "auto", height = 16, isBig = true) => {
  return serviceTypes.map(serviceType => {
    return {value: +serviceType, label: ServiceTypesImg(+serviceType, false, width, height, isBig), id: +serviceType}
  });
}