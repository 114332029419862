import {apiRedux} from "../reducers/apiRedux";
import {apiUrls} from "../../constants/appUrls";
import {ServiceText} from "../../common/seriveTypes";

const ProvidersApi = apiRedux.injectEndpoints({
  endpoints: (build) => ({

    getProviders: build.query({
      query: (data) => ({
        url: data ?
          apiUrls.getProvidersListByServiceType.formatURL({serviceType: data.serviceType}):
          apiUrls.getProvidersList,
        method: 'GET',
      }),
      keepUnusedDataFor: 2,
      providesTags: (result) => [{type: 'providers_list', id: result.id}],
      transformResponse: (response) => {
        return response?.providers?.map(item => {
          return {
            ...item,
            value: item.id,
            label: item.shortName,
            serviceTypeText: ServiceText(item.serviceType),

          }
        })
      }
    }),
    createProvider: build.mutation({
      query: (data) => ({
        url: apiUrls.createProviderURL,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['providers_list'],
    }),
    updateProvider: build.mutation({
      query: (data) => ({
        url: apiUrls.updateProviderURL,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['providers_list'],
    }),
  })
});

export const {useGetProvidersQuery} = ProvidersApi;
export const {useCreateProviderMutation} = ProvidersApi;
export const {useUpdateProviderMutation} = ProvidersApi;
