import React, {useEffect} from 'react';
import moment from "moment";
import packageJson from "../../package.json";
import {delLoginCookie, unAuthModal} from "./api";
import {baseUrl} from "../constants/appUrls";


const ReloadPageAfterDeployment = () => {

  const buildDateGreaterThan = (latestDate, currentDate) => {
    const momLatestDateTime = moment(latestDate);
    const momCurrentDateTime = moment(currentDate);
    return momLatestDateTime.isAfter(momCurrentDateTime);
  };

  const reloadIfNewVersion = () => {
    fetch(`${baseUrl}meta.json`)
      .then((response) => response.json())
      .then((meta) => {
        const latestVersionDate = meta.buildDate;
        const currentVersionDate = packageJson.buildDate;
        const shouldForceRefresh = buildDateGreaterThan(
          latestVersionDate,
          currentVersionDate
        );
        if (shouldForceRefresh) {
          delLoginCookie();
          unAuthModal("").then(() => {
            window.location.reload();
          });
        }
      });
  }

  useEffect(() => {
      window.addEventListener("click", reloadIfNewVersion);
      return () => window.removeEventListener("click", reloadIfNewVersion);
    },
    [])
  return (<> </>);
};

export default ReloadPageAfterDeployment;