import i18next from "i18next";

export const PaylineTypeFormatter = (type) => {
  switch (type) {
    // BY_MASTER: 0,
    //   LEGAL: 1, // было 2
    //   INDIVIDUAL: 2, //было 3
    //   MANUAL: 3,
    case "MANUAL":
    case 3:
      console.log("type3", type)
      return i18next.t("manualManagement");
    case "INDIVIDUAL":
    case 2:
      console.log("type2", type)
      return i18next.t("SoleProprietor");
    case "LEGAL":
    case 1:
      console.log("type1", type)
      return i18next.t("LegalEntity");
    default:
      return "";
  }
}

export const payerTypeForBackend = (type) => {
  switch (type) {
    case i18next.t("manualManagement"):
      return "MANUAL";
    case  i18next.t("SoleProprietor"):
      return "INDIVIDUAL";
    case  i18next.t("LegalEntity"):
      return "LEGAL";
    default:
      return "";
  }
}