import React from 'react';
import ClientCardTop from "./ClientCardTop";
import { useSelector} from "react-redux";
import {Load} from "../../components/load/Load";

const ClientCard = ({id}) => {
  const {cardInfoLoad} = useSelector((state) => state.tableData);

  if (cardInfoLoad) {
    return <Load/>
  }
  return (
    <div className={"pt-4 pb-2"}>
      <ClientCardTop id={id}/>
      {/*<AccountList />*/}
    </div>
  );
};

export default ClientCard;