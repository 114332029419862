import React from 'react';
import Modal from "./Modal";
import {useFormik} from "formik";
import * as Yup from "yup";
import swal from "sweetalert";

import FormikField from "../formComponents/FormikField";
import api from "../../utils/api";
import {loadTableData} from "reduxStore/reducers/tableData";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import FormikSelect from "../formComponents/FormikSelect";
import {ServiceIdType} from "../../common/seriveTypes";
import {Load} from "../load/Load";
import {useGetProvidersQuery} from "../../redux/api/ProvidersApi";

const ModalAddIdFBAccount = ({data, show, closeCallback}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {data: providersList, isLoading: isLoadProviders} = useGetProvidersQuery({serviceType: ServiceIdType.Facebook});

  const formik = useFormik({
    initialValues: {
      Id: "",
      providers: {value: "", label: ""},
      advName: data?.adwName || ""
    },
    validationSchema: Yup.object({
      Id: Yup.number().typeError(t("validation.enterNumber")).required(t("validation.required")),
      providers: Yup.object()
        .test("testClient", t("validation.required"), (value) => {
          return typeof value.value !== "undefined";
        })
        .required(t("validation.required")),
      advName: Yup.string().required(t("validation.required"))
    }),
    onSubmit: ({Id, providers, advName}) => {
      let submitData = {
        facebookAccountId: Id,
        registrationId: data.id,
        providerId: providers?.value,
        advName: advName
      }

      swal({
        text: t("addFBIDConfirm"),
        icon: "warning",
        buttons: [t("no"), t("yes")],
        className: "confirm-simple-modal"
      }).then((response) => {
        if (response) {
          api("/facebook/add-account-id", {
            method: "POST",
            data: submitData
          })
            .then((response) => {
              if (response) {
                dispatch(loadTableData("requestFB"));
                closeCallback();
                swal({
                  icon: "success",
                  text: t("createAccFromRequestSuccess", {service: "Facebook"})
                })
              }

              // if (response) {
              //   api(`/facebook/registration/delete/${data.id}`, {
              //     method: "GET",
              //   }).then(() => {
              //     dispatch(loadTableData("requestFB"));
              //     closeCallback();
              //     swal({
              //       icon: "success",
              //       text: t("createAccFromRequestSuccess", {service: "Facebook"})
              //     })
              //   })
              // }
            }).finally(() => formik.setSubmitting(false));
        } else {
          closeCallback();
        }
      })
    }
  })
  return (
    <Modal
      show={show}
      closeCallback={closeCallback}
      notCloseOnOverlay={true}
      title={t("addIDFB")}
    >
      <form
        onSubmit={formik.handleSubmit}
        className="row p-3"
        style={{width: "440px"}}
      >
        {isLoadProviders && <Load/>}
        <div className="w-100">
          <label htmlFor="attempts" className="">
            {"ID Facebook"}
          </label>
          <FormikField
            name="Id"
            formik={formik}
            className="pb-3 pl-0 position-relative"
          />
        </div>
        <div className={"w-100 position-relative"}>
          <label>{t("providers")}</label>
          <FormikSelect
            name={"providers"}
            formik={formik}
            options={providersList}
            className={"pb-4"}
          />
        </div>
        <div className={"w-100 pb-5 position-relative"}>
          <label>{t("advName")}</label>
          <FormikField
            name="advName"
            formik={formik}
            className="pb-3 pl-0 position-relative"
          />
        </div>
        <div className={"w-100 text-center pt-4"}>
          <button
            disabled={formik.isSubmitting}
            type={"submit"}
            className={"btn btn-primary mr-3 px-4"}>{t("save")}</button>
          <button
            className={"btn btn-outline-primary px-4"}
            onClick={() => closeCallback()}
          >{t("cancel")}
          </button>
        </div>
      </form>
    </Modal>
  )
}

export default ModalAddIdFBAccount;