import i18next from "i18next";
import {publicUrl} from "../../constants/appUrls";

export const showNewFavicon = (type = "", text = "Новое сообщение") => {

  // const showNewFaviconLocal = ()=>{
  //   console.log("test favicon")
  //   showNewFavicon("default", "old text")
  // }
  // window.addEventListener("focus",  showNewFaviconLocal);
  // return () => {
  //   window.removeEventListener("focus",  showNewFaviconLocal)


  // alert("test", type)
  console.log("type", type)
  console.log("window", window)
  let newTxt = text;
  let link = document.querySelector("link[rel*='shortcut icon']") || document.createElement('link');
  link.type = 'image/png';
  link.rel = 'shortcut icon';
  document.title = newTxt;
  // if (!document.hasFocus()) {
  //   link.href = '/favicon.ico';
  // } else {
  link.href = type === "notification" ? `${publicUrl}/favicon1.ico` : `${publicUrl}/favicon.ico`;

}

export function notifyMe(data = "", id = "") {
  if (!("Notification" in window)) {
    // Check if the browser supports notifications
    alert("This browser does not support desktop notification");
  } else if (Notification.permission === "granted") {
    console.log("Notification1", Notification.permission)
    // Check whether notification permissions have already been granted;
    // if so, create a notification
    let body = `${i18next.t("PaymentNotificationHTMLTitle")} ${data}`;
    const notification = new Notification("Payment notification", {
      tag: id,
      icon: "/favicon.ico",
      requireInteraction: true,
      body: body
    });
    // addDotToTab(data)
    showNewFavicon("notification", i18next.t("PaymentNotificationHTMLTitle"));
    // …
  } else if (Notification.permission !== "denied") {
    console.log("Notification2", Notification)

    // We need to ask the user for permission
    Notification.requestPermission().then((permission) => {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        const notification = new Notification(i18next.t("PaymentNotificationHTMLTitle") + "2" + data, {tag: id});
        // addDotToTab(data)
        showNewFavicon("notification", i18next.t("PaymentNotificationHTMLTitle"));
        // …
      }
    });
  }

  // At last, if the user has denied notifications, and you
  // want to be respectful there is no need to bother them anymore.
}

